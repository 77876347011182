<template>
  <div>
    <subdivisions-add></subdivisions-add>
  </div>
</template>

<script>
import SubdivisionsAdd from "@/components/ecologist/subdivisions/subdivisionsAdd";
export default {
  name: "subdivisionsAddView",
  components: { SubdivisionsAdd },
};
</script>

<style scoped></style>
