<template>
  <div>
    <el-form
      :rules="rules"
      :model="addSubDivisionForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="title" label="Наименование подразделения">
            <el-input v-model="addSubDivisionForm.title"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="email" label="Почта">
            <el-input v-model="addSubDivisionForm.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Фактический адрес нахождения подразделения</h4>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="region" label="Область">
            <el-select
              clearable
              @change="changeArea"
              class="searchPole"
              v-model="addSubDivisionForm.areaInfo"
              value-key="id"
              placeholder="Область"
            >
              <el-option
                v-for="item in areaNames.content"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="district" label="Район">
            <el-select
              clearable
              @change="changeDistrict"
              :disabled="addSubDivisionForm.areaInfo === null"
              class="searchPole"
              v-model="addSubDivisionForm.districtInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchDistrictNames"
            >
              <el-option
                v-for="item in districtNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="locality" label="Населенный пункт">
            <el-select
              clearable
              :disabled="addSubDivisionForm.districtInfo === null"
              class="searchPole"
              v-model="addSubDivisionForm.cityInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Населенный пункт"
              :remote-method="searchCitiesNames"
            >
              <el-option
                v-for="item in citiesNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="street" label="Улица">
            <el-input v-model="addSubDivisionForm.street"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="phoneNumber" label="Телефон">
            <el-input v-model="addSubDivisionForm.phoneNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="parentInfo" label="Объект/Филиал">
            <el-select
              clearable
              class="searchPole"
              v-model="addSubDivisionForm.parentInfo"
              value-key="id"
              placeholder="Объект/Филиал"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Добавить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "subdivisionsAdd",
  mixins: [notification],
  data() {
    return {
      addSubDivisionForm: {
        parentInfo: null,
        cityInfo: null,
        districtInfo: {id:-1},
        areaInfo: {id:-1},
        organizationId: null,
        street: null,
        phoneNumber: null,
        email: null,
        responsiblePersonsInfo: null,
        title: null,
        organizationType: null,
        type: 2,
      },
      rules: {
        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        parentInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        cityInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        districtInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        areaInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        responsiblePersonsInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      areaNames: "GETLISTALLAREAS",
      districtNames: "GETLISTDISTRICNAMES",
      citiesNames: "GETLISTCITIESNAMES",
      responsiblePersonList: "GETRESPONSIBLEPERSONLIST",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    searchResponsiblePerson(query) {
      this.searchResponsiblePersonByQuery(query);
    },

    searchDistrictNames(query) {
      this.getDistrictNames(query);
    },

    searchCitiesNames(query) {
      this.getCitiesNames(query);
    },

    changeArea() {
      this.searchDistrictNames("");
    },

    changeDistrict() {
      this.searchCitiesNames("");
    },

    save() {
      
      this.addSubDivisionForm.areaId = this.addSubDivisionForm.areaInfo.id != -1 && this.addSubDivisionForm.areaInfo !="" ? this.addSubDivisionForm.areaInfo.id : null;
      this.addSubDivisionForm.districtId = this.addSubDivisionForm.districtInfo.id!= -1 && this.addSubDivisionForm.districtInfo !="" ?  this.addSubDivisionForm.districtInfo.id : null;
      this.addSubDivisionForm.areaInfo = this.addSubDivisionForm.areaInfo !="" ? this.addSubDivisionForm.areaInfo : null;
      this.addSubDivisionForm.districtInfo = this.addSubDivisionForm.districtInfo !="" ? this.addSubDivisionForm.districtInfo : null;
      this.addSubDivisionForm.cityInfo = this.addSubDivisionForm.cityInfo !=""? this.addSubDivisionForm.cityInfo : {id:-1};
      this.addSubDivisionForm.street = this.addSubDivisionForm.street !="" ? this.addSubDivisionForm.street : null;
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("createUnit", this.addSubDivisionForm)
            .then(() => {
              this.notification(
                "Успешно",
                "Подразделение добавлено",
                "success"
              );
              this.$store.dispatch("getListUnits", {
                unitType: 2,
                size: 20,
                page: 0,
              });
              this.$store.dispatch("setShowModal", null);
            });
        }
      });
    },

    getListAllAreas() {
      this.$store.dispatch("getListAllAreas", {
        page: 0,
        size: 20,
      });
    },

    getDistrictNames(query) {
      this.$store.dispatch("getDistrictNames", {
        areaId: this.addSubDivisionForm.areaInfo.id,
        query: query,
      });
    },

    getCitiesNames(query) {
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.addSubDivisionForm.areaInfo.id,
        districtId: this.addSubDivisionForm.districtInfo.id,
        query: query,
      });
    },

    searchResponsiblePersonByQuery(query) {
      this.$store.dispatch("searchResponsiblePerson", {
        page: 0,
        size: 20,
        query: query,
      });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 1,
      });
    },
  },

  mounted() {
    this.getOrganizationHierarchyByType();
    this.getListAllAreas();
  },
};
</script>

<style scoped></style>
